import { Controller } from "@hotwired/stimulus";

export class NavMobileToggle extends Controller {
  static outlets = ["nav-mobile-menu"];
  static targets = ["icon"];

  toggleMenu(_) {
    this.navMobileMenuOutlet.toggle();
    this.iconTarget.classList.remove("fa-bars", "fa-chevron-up");
    this.iconTarget.classList.add(this.isOpen() ? "fa-chevron-up" : "fa-bars");
  }

  isOpen() {
    return !!this.navMobileMenuOutlet.element.classList.contains("open");
  }
}

export class NavMobileMenu extends Controller {
  toggle() {
    if (this.element.classList.contains("open")) {
      this.element.classList.remove("open");
    } else {
      this.element.classList.add("open");
    }
  }
}

export default {};
