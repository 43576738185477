import { Controller } from "@hotwired/stimulus";

export class FileUpload extends Controller {
  static targets = ["carousel"];
  static outlets = ["file-upload-form"];
  change(e) {
    for (let file of e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let div = document.createElement("div");
        div.classList.add("item");
        let img = document.createElement("img");
        img.src = reader.result;
        div.appendChild(img);
        this.carouselTarget.appendChild(div);
      };
      reader.readAsDataURL(file);
      
      let input = document.createElement("input");
      input.type = "file";
      input.hidden = true;
      input.name = "images[]"
      let list = new DataTransfer();
      list.items.add(file);
      input.files = list.files;
      this.fileUploadFormOutlet.element.appendChild(input);
    }
  }
}

export class FileUploadForm extends Controller {
}

export class FileItem extends Controller {
  static outlets = ["file-upload-form"];
  remove(e) {
    let conf = confirm("Estas seguro que quieres eliminar este archivo?");
    if (!conf) return;
    let input = document.createElement("input");
    input.name = "to_detach[]";
    input.type = "text";
    input.value = e.params.fileId;
    input.hidden = true;
    this.fileUploadFormOutlet.element.appendChild(input);
    this.element.remove();
  }
}

export default {};
