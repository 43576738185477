import { Controller } from "@hotwired/stimulus"

export class Confirm extends Controller {
  static values = { 
    message: String 
  }
  click(e) {
    if (!confirm(this.messageValue)) {
      e.preventDefault();
    }
  }
}

export default {};