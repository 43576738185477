import { Controller } from "@hotwired/stimulus";

import db from "./helpers/municipalities";

export class StateSelector extends Controller {
  static outlets = ["muni-selector"];
  static values = {
    initial: String
  };

  connect() {
    var statesSet = new Set();
    for (let row of db) {
      statesSet.add(row["departamento"]);
    }
    this.element.innerHTML = `<option value=""></option>`;
    this.element.innerHTML += Array
      .from(statesSet)
      .map(s => `<option value="${s}" ${ this.initialValue == s ? "selected" : "" }>${s}</option>`)
      .join("");
    if (this.initialValue) {
      this.muniSelectorOutlet.filterMunisFor(this.initialValue);
    }
  }

  stateChange(e) {
    this.muniSelectorOutlet.filterMunisFor(e.target.value);
  }
}

export class MuniSelector extends Controller {
  static outlets = ["state-selector"];
  static values = {
    initial: String
  };
  
  filterMunisFor(state) {
    var munis = [];
    for (let row of db) {
      if (row["departamento"] == state) {
        munis.push(row["municipio"]);
      }
    }
    this.element.innerHTML = `<option value=""></option>`;
    this.element.innerHTML += munis
      .map(s => `<option value="${s}" ${ this.initialValue == s ? "selected" : "" }>${s}</option>`)
      .join("");
  }
}

export default {};
