import { Controller } from "@hotwired/stimulus";

import { CSRFToken } from "./helpers/cookie";

const LOCALSTORAGE_KEY = "cart";
const PRODUCT_ID = "product_id";
const CANT = "cant";
const COLOR_ID = "color_id";

const _getCart = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || "[]");
};

const _getCartSerialized = () => {
  return _getCart().reduce((acum, item) => `${ item[PRODUCT_ID] }, ${ item[CANT] };${ acum }`, '');
};

const _addToCart = (options = {}) => {
  let cart = _getCart();
  let found = false;
  for (let item of cart) {
    if ((item[PRODUCT_ID] == options[PRODUCT_ID]) && (item[COLOR_ID] == options[COLOR_ID])) {
      item[CANT] += 1;
      found = true;
    }
  }
  if (!found) {
    let neo = {};
    neo[PRODUCT_ID] = options[PRODUCT_ID];
    neo[CANT] = 1;
    neo[COLOR_ID] = options[COLOR_ID];
    cart.push(neo);
  }
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(cart));
};

const _count = () => {
  const cart = _getCart();
  return cart.reduce((res, curr) => res + curr[CANT], 0);
};

const _remove = (productId, colorId) => {
  localStorage.setItem(
    LOCALSTORAGE_KEY, 
    JSON.stringify(_getCart().filter(x => !(x[PRODUCT_ID] == productId && x[COLOR_ID] == colorId)))
  );
};

const _update = (productId, side, colorId) => {
  var newCart = [];
  for (let item of _getCart()) {
    if (item[PRODUCT_ID] == productId && item[COLOR_ID] == colorId) {
      if (side == "+") {
        newCart.push({ ...item, cant: item[CANT] + 1 });
      } else if (item[CANT] > 1) {
        newCart.push({ ...item, cant: item[CANT] - 1 });
      }
    } else {
      newCart.push({ ...item });
    }
  }
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newCart));
};

export class CartCountDisplay extends Controller {
  static targets = ["count"];
  connect() {
    this.refresh();
  }
  refresh() {
    this.countTarget.innerHTML = `(${ _count() })`;
  }
};

export class AddToCart extends Controller {
  static outlets = ["cart-count-display"];
  static values = {
    colors: Boolean
  };
  static targets = ["button"];
  colorId = null;
  connect() {
    if (this.colorsValue && this.colorId == null) {
      this.buttonTarget.disabled = true;  
    }
  }
  addToCart(e) {
    let options = {};
    options[PRODUCT_ID] = e.params.productId;
    options[COLOR_ID] = this.colorId;
    _addToCart(options);
    for (let outlet of this.cartCountDisplayOutlets) {
      outlet.refresh();
    }
  }
}

export class DisplayCart extends Controller {
  static values = {
    cart: Object
  };
  connect() {
    this.refresh();
  }
  refresh() {
    const cart = _getCart();
    const query = cart.reduce((acum, item) => `${ item[PRODUCT_ID] },${ item[CANT] },${ item[COLOR_ID] };${ acum }`, '');
    fetch("/cart", { method: "POST", body: query, headers: { "X-CSRF-Token": CSRFToken(document.cookie) } })
      .then(res => res.text())
      .then(html => (this.element.innerHTML = html))
    ;
  }
}

export class CartItem extends Controller {
  static outlets = ["display-cart", "cart-count-display"];
  remove(e) {
    _remove(e.params.productId);
    this.cartCountDisplayOutlets.forEach(o => o.refresh());
    this.displayCartOutlets.forEach(cart => cart.refresh());
  }
  changeAmount(e) {
    _update(e.params.productId, e.params.side);
    this.cartCountDisplayOutlets.forEach(o => o.refresh());
    this.displayCartOutlets.forEach(cart => cart.refresh());
  }
};

export class ProductList extends Controller {
  connect() {
    this.refresh();
  }
  refresh() {
    const cart = _getCart();
    const query = cart.reduce((acum, item) => `${ acum };${ item[PRODUCT_ID] },${ item[CANT] },${ item[COLOR_ID] }`, '');
    fetch("/cart/products", { method: "POST", body: query, headers: { "X-CSRF-Token": CSRFToken(document.cookie) } })
      .then(res => res.text())
      .then(html => (this.element.innerHTML = html))
    ;
  }
}

export class ProductListItem extends Controller {
  static outlets = ["product-list", "cart-count-display"];
  remove(e) {
    _remove(e.params.productId, e.params.colorId);
    this.cartCountDisplayOutlets.forEach(o => o.refresh());
    this.productListOutlets.forEach(cart => cart.refresh());
  }
  changeAmount(e) {
    _update(e.params.productId, e.params.side, e.params.colorId);
    this.cartCountDisplayOutlets.forEach(o => o.refresh());
    this.productListOutlets.forEach(cart => cart.refresh());
  }
}

export class ProductListTotal extends Controller {
  static outlets = ["product-list-total-display"];
  static values = {
    total: Number
  }
  connect() {
    this.broadcast();
  }
  broadcast() {
    this.productListTotalDisplayOutlets.forEach(o => o.refresh(this.totalValue));
  }
}

export class ProductListTotalDisplay extends Controller {
  static outlets = ["price-display"];
  refresh(total) {
    this.priceDisplayOutlets[0].priceValue = total;
    this.priceDisplayOutlets[0].refresh();
    this.priceDisplayOutlets[1].priceValue = total + 4_000;
    this.priceDisplayOutlets[1].refresh();
  }
}

export class CartCheckoutForm extends Controller {
  static targets = ["products"];
  submit() {
    this.productsTarget.value = _getCartSerialized();
  }
}

export class ColorPicker extends Controller {
  static outlets = ["add-to-cart"];
  colors = [];
  select(e) {
    this.addToCartOutlet.colorId = e.params.colorId;
    debugger;
  }
}

export class ColorPickerItem extends Controller {
  static outlets = ["color-picker", "add-to-cart"];
  select(e) {
    this.addToCartOutlet.colorId = e.params.colorId;
    this.addToCartOutlet.buttonTarget.disabled = false;
    document
      .querySelectorAll(".color-picker .color-picker__item")
      .forEach(e => e.classList.remove("color-picker__item--selected"));
    this.element.classList.add("color-picker__item--selected");
  }
}

export default {};
