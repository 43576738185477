import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    price: Number,
    symbol: String
  }

  connect() {
    this.refresh();
  }

  refresh() {
    var parts = this.priceValue.toString().split("").reverse();
    var result = [];
    for (var i = 0; i < parts.length; i++) {
      if (i != 0 && i % 3 == 0) {
        result.push(".");
      }
      result.push(parts[i]);
    }
    this.element.innerHTML = `${ this.symbolValue} ${ result.reverse().join("") }`;
  }
}
