import { Controller } from "@hotwired/stimulus";

export class ImageCarousel extends Controller {
  static targets = ["current"];

  select(e) {
    var selected = e.target.src;
    var current = this.currentTarget.src;
    e.target.src = current;
    this.currentTarget.src = selected;
  }
}

export default {}