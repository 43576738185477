export default [
{"municipio": "El Encanto", "departamento": "Amazonas"},
{"municipio": "La Chorrera", "departamento": "Amazonas"},
{"municipio": "La Pedrera", "departamento": "Amazonas"},
{"municipio": "La Victoria", "departamento": "Amazonas"},
{"municipio": "Leticia", "departamento": "Amazonas"},
{"municipio": "Mirití-Paraná", "departamento": "Amazonas"},
{"municipio": "Puerto Alegría", "departamento": "Amazonas"},
{"municipio": "Puerto Arica", "departamento": "Amazonas"},
{"municipio": "Puerto Nariño", "departamento": "Amazonas"},
{"municipio": "Puerto Santander", "departamento": "Amazonas"},
{"municipio": "Tarapacá", "departamento": "Amazonas"},
{"municipio": "Abejorral", "departamento": "Antioquia"},
{"municipio": "Abriaquí", "departamento": "Antioquia"},
{"municipio": "Alejandría", "departamento": "Antioquia"},
{"municipio": "Amagá", "departamento": "Antioquia"},
{"municipio": "Amalfi", "departamento": "Antioquia"},
{"municipio": "Andes", "departamento": "Antioquia"},
{"municipio": "Angelópolis", "departamento": "Antioquia"},
{"municipio": "Angostura", "departamento": "Antioquia"},
{"municipio": "Anorí", "departamento": "Antioquia"},
{"municipio": "Santa Fe de Antioquia", "departamento": "Antioquia"},
{"municipio": "Anzá", "departamento": "Antioquia"},
{"municipio": "Apartadó", "departamento": "Antioquia"},
{"municipio": "Arboletes", "departamento": "Antioquia"},
{"municipio": "Argelia", "departamento": "Antioquia"},
{"municipio": "Armenia", "departamento": "Antioquia"},
{"municipio": "Barbosa", "departamento": "Antioquia"},
{"municipio": "Bello", "departamento": "Antioquia"},
{"municipio": "Belmira", "departamento": "Antioquia"},
{"municipio": "Betania", "departamento": "Antioquia"},
{"municipio": "Betulia", "departamento": "Antioquia"},
{"municipio": "Briceño", "departamento": "Antioquia"},
{"municipio": "Buriticá", "departamento": "Antioquia"},
{"municipio": "Cáceres", "departamento": "Antioquia"},
{"municipio": "Caicedo", "departamento": "Antioquia"},
{"municipio": "Caldas", "departamento": "Antioquia"},
{"municipio": "Campamento", "departamento": "Antioquia"},
{"municipio": "Cañasgordas", "departamento": "Antioquia"},
{"municipio": "Caracolí", "departamento": "Antioquia"},
{"municipio": "Caramanta", "departamento": "Antioquia"},
{"municipio": "Carepa", "departamento": "Antioquia"},
{"municipio": "Carmen de Viboral", "departamento": "Antioquia"},
{"municipio": "Carolina del Príncipe", "departamento": "Antioquia"},
{"municipio": "Caucasia", "departamento": "Antioquia"},
{"municipio": "Chigorodó", "departamento": "Antioquia"},
{"municipio": "Cisneros", "departamento": "Antioquia"},
{"municipio": "Ciudad Bolívar", "departamento": "Antioquia"},
{"municipio": "Cocorná", "departamento": "Antioquia"},
{"municipio": "Concepción", "departamento": "Antioquia"},
{"municipio": "Concordia", "departamento": "Antioquia"},
{"municipio": "Copacabana", "departamento": "Antioquia"},
{"municipio": "Dabeiba", "departamento": "Antioquia"},
{"municipio": "Donmatías", "departamento": "Antioquia"},
{"municipio": "Ebéjico", "departamento": "Antioquia"},
{"municipio": "El Bagre", "departamento": "Antioquia"},
{"municipio": "El Peñol", "departamento": "Antioquia"},
{"municipio": "El Retiro", "departamento": "Antioquia"},
{"municipio": "El Santuario", "departamento": "Antioquia"},
{"municipio": "Entrerríos", "departamento": "Antioquia"},
{"municipio": "Envigado", "departamento": "Antioquia"},
{"municipio": "Fredonia", "departamento": "Antioquia"},
{"municipio": "Frontino", "departamento": "Antioquia"},
{"municipio": "Giraldo", "departamento": "Antioquia"},
{"municipio": "Girardota", "departamento": "Antioquia"},
{"municipio": "Gómez Plata", "departamento": "Antioquia"},
{"municipio": "Granada", "departamento": "Antioquia"},
{"municipio": "Guadalupe", "departamento": "Antioquia"},
{"municipio": "Guarne", "departamento": "Antioquia"},
{"municipio": "Guatapé", "departamento": "Antioquia"},
{"municipio": "Heliconia", "departamento": "Antioquia"},
{"municipio": "Hispania", "departamento": "Antioquia"},
{"municipio": "Itagüí", "departamento": "Antioquia"},
{"municipio": "Ituango", "departamento": "Antioquia"},
{"municipio": "Jardín", "departamento": "Antioquia"},
{"municipio": "Jericó", "departamento": "Antioquia"},
{"municipio": "La Ceja", "departamento": "Antioquia"},
{"municipio": "La Estrella", "departamento": "Antioquia"},
{"municipio": "La Pintada", "departamento": "Antioquia"},
{"municipio": "La Unión", "departamento": "Antioquia"},
{"municipio": "Liborina", "departamento": "Antioquia"},
{"municipio": "Maceo", "departamento": "Antioquia"},
{"municipio": "Marinilla", "departamento": "Antioquia"},
{"municipio": "Medellín", "departamento": "Antioquia"},
{"municipio": "Montebello", "departamento": "Antioquia"},
{"municipio": "Murindó", "departamento": "Antioquia"},
{"municipio": "Mutatá", "departamento": "Antioquia"},
{"municipio": "Nariño", "departamento": "Antioquia"},
{"municipio": "Nechí", "departamento": "Antioquia"},
{"municipio": "Necoclí", "departamento": "Antioquia"},
{"municipio": "Olaya", "departamento": "Antioquia"},
{"municipio": "Peque", "departamento": "Antioquia"},
{"municipio": "Pueblorrico", "departamento": "Antioquia"},
{"municipio": "Puerto Berrío", "departamento": "Antioquia"},
{"municipio": "Puerto Nare", "departamento": "Antioquia"},
{"municipio": "Puerto Triunfo", "departamento": "Antioquia"},
{"municipio": "Remedios", "departamento": "Antioquia"},
{"municipio": "Rionegro", "departamento": "Antioquia"},
{"municipio": "Sabanalarga", "departamento": "Antioquia"},
{"municipio": "Sabaneta", "departamento": "Antioquia"},
{"municipio": "Salgar", "departamento": "Antioquia"},
{"municipio": "San Andrés de Cuerquia", "departamento": "Antioquia"},
{"municipio": "San Carlos", "departamento": "Antioquia"},
{"municipio": "San Francisco", "departamento": "Antioquia"},
{"municipio": "San Jerónimo", "departamento": "Antioquia"},
{"municipio": "San José de la Montaña", "departamento": "Antioquia"},
{"municipio": "San Juan de Urabá", "departamento": "Antioquia"},
{"municipio": "San Luis", "departamento": "Antioquia"},
{"municipio": "San Pedro de los Milagros", "departamento": "Antioquia"},
{"municipio": "San Pedro de Urabá", "departamento": "Antioquia"},
{"municipio": "San Rafael", "departamento": "Antioquia"},
{"municipio": "San Roque", "departamento": "Antioquia"},
{"municipio": "San Vicente", "departamento": "Antioquia"},
{"municipio": "Santa Bárbara", "departamento": "Antioquia"},
{"municipio": "Santa Rosa de Osos", "departamento": "Antioquia"},
{"municipio": "Santo Domingo", "departamento": "Antioquia"},
{"municipio": "Segovia", "departamento": "Antioquia"},
{"municipio": "Sonsón", "departamento": "Antioquia"},
{"municipio": "Sopetrán", "departamento": "Antioquia"},
{"municipio": "Támesis", "departamento": "Antioquia"},
{"municipio": "Tarazá", "departamento": "Antioquia"},
{"municipio": "Tarso", "departamento": "Antioquia"},
{"municipio": "Titiribí", "departamento": "Antioquia"},
{"municipio": "Toledo", "departamento": "Antioquia"},
{"municipio": "Turbo", "departamento": "Antioquia"},
{"municipio": "Uramita", "departamento": "Antioquia"},
{"municipio": "Urrao", "departamento": "Antioquia"},
{"municipio": "Valdivia", "departamento": "Antioquia"},
{"municipio": "Valparaíso", "departamento": "Antioquia"},
{"municipio": "Vegachí", "departamento": "Antioquia"},
{"municipio": "Venecia", "departamento": "Antioquia"},
{"municipio": "Vigía del Fuerte", "departamento": "Antioquia"},
{"municipio": "Yalí", "departamento": "Antioquia"},
{"municipio": "Yarumal", "departamento": "Antioquia"},
{"municipio": "Yolombó", "departamento": "Antioquia"},
{"municipio": "Yondó", "departamento": "Antioquia"},
{"municipio": "Zaragoza", "departamento": "Antioquia"},
{"municipio": "Arauca", "departamento": "Arauca"},
{"municipio": "Arauquita", "departamento": "Arauca"},
{"municipio": "Cravo Norte", "departamento": "Arauca"},
{"municipio": "Fortul", "departamento": "Arauca"},
{"municipio": "Puerto Rondón", "departamento": "Arauca"},
{"municipio": "Saravena", "departamento": "Arauca"},
{"municipio": "Tame", "departamento": "Arauca"},
{"municipio": "Baranoa", "departamento": "Atlántico"},
{"municipio": "Barranquilla", "departamento": "Atlántico"},
{"municipio": "Campo de La Cruz", "departamento": "Atlántico"},
{"municipio": "Candelaria", "departamento": "Atlántico"},
{"municipio": "Galapa", "departamento": "Atlántico"},
{"municipio": "Juan de Acosta", "departamento": "Atlántico"},
{"municipio": "Luruaco", "departamento": "Atlántico"},
{"municipio": "Malambo", "departamento": "Atlántico"},
{"municipio": "Manatí", "departamento": "Atlántico"},
{"municipio": "Palmar de Varela", "departamento": "Atlántico"},
{"municipio": "Piojó", "departamento": "Atlántico"},
{"municipio": "Polonuevo", "departamento": "Atlántico"},
{"municipio": "Ponedera", "departamento": "Atlántico"},
{"municipio": "Puerto Colombia", "departamento": "Atlántico"},
{"municipio": "Repelón", "departamento": "Atlántico"},
{"municipio": "Sabanagrande", "departamento": "Atlántico"},
{"municipio": "Sabanalarga", "departamento": "Atlántico"},
{"municipio": "Santa Lucía", "departamento": "Atlántico"},
{"municipio": "Santo Tomás", "departamento": "Atlántico"},
{"municipio": "Soledad", "departamento": "Atlántico"},
{"municipio": "Suán", "departamento": "Atlántico"},
{"municipio": "Tubará", "departamento": "Atlántico"},
{"municipio": "Usiacurí", "departamento": "Atlántico"},
{"municipio": "Achí", "departamento": "Bolívar"},
{"municipio": "Altos del Rosario", "departamento": "Bolívar"},
{"municipio": "Arenal", "departamento": "Bolívar"},
{"municipio": "Arjona", "departamento": "Bolívar"},
{"municipio": "Arroyohondo", "departamento": "Bolívar"},
{"municipio": "Barranco de Loba", "departamento": "Bolívar"},
{"municipio": "Calamar", "departamento": "Bolívar"},
{"municipio": "Cantagallo", "departamento": "Bolívar"},
{"municipio": "Cartagena de Indias", "departamento": "Bolívar"},
{"municipio": "Cicuco", "departamento": "Bolívar"},
{"municipio": "Clemencia", "departamento": "Bolívar"},
{"municipio": "Córdoba", "departamento": "Bolívar"},
{"municipio": "El Carmen de Bolívar", "departamento": "Bolívar"},
{"municipio": "El Guamo", "departamento": "Bolívar"},
{"municipio": "El Peñón", "departamento": "Bolívar"},
{"municipio": "Hatillo de Loba", "departamento": "Bolívar"},
{"municipio": "Magangué", "departamento": "Bolívar"},
{"municipio": "Mahates", "departamento": "Bolívar"},
{"municipio": "Margarita", "departamento": "Bolívar"},
{"municipio": "María La Baja", "departamento": "Bolívar"},
{"municipio": "Montecristo", "departamento": "Bolívar"},
{"municipio": "Morales", "departamento": "Bolívar"},
{"municipio": "Norosí", "departamento": "Bolívar"},
{"municipio": "Pinillos", "departamento": "Bolívar"},
{"municipio": "Regidor", "departamento": "Bolívar"},
{"municipio": "Río Viejo", "departamento": "Bolívar"},
{"municipio": "San Cristóbal", "departamento": "Bolívar"},
{"municipio": "San Estanislao", "departamento": "Bolívar"},
{"municipio": "San Fernando", "departamento": "Bolívar"},
{"municipio": "San Jacinto", "departamento": "Bolívar"},
{"municipio": "San Jacinto del Cauca", "departamento": "Bolívar"},
{"municipio": "San Juan Nepomuceno", "departamento": "Bolívar"},
{"municipio": "San Martín de Loba", "departamento": "Bolívar"},
{"municipio": "San Pablo", "departamento": "Bolívar"},
{"municipio": "Santa Catalina", "departamento": "Bolívar"},
{"municipio": "Santa Cruz de Mompox", "departamento": "Bolívar"},
{"municipio": "Santa Rosa de Lima", "departamento": "Bolívar"},
{"municipio": "Santa Rosa del Sur", "departamento": "Bolívar"},
{"municipio": "Simití", "departamento": "Bolívar"},
{"municipio": "Soplaviento", "departamento": "Bolívar"},
{"municipio": "Talaigua Nuevo", "departamento": "Bolívar"},
{"municipio": "Tiquisio", "departamento": "Bolívar"},
{"municipio": "Turbaco", "departamento": "Bolívar"},
{"municipio": "Turbaná", "departamento": "Bolívar"},
{"municipio": "Villanueva", "departamento": "Bolívar"},
{"municipio": "Zambrano", "departamento": "Bolívar"},
{"municipio": "Almeida", "departamento": "Boyacá"},
{"municipio": "Aquitania", "departamento": "Boyacá"},
{"municipio": "Arcabuco", "departamento": "Boyacá"},
{"municipio": "Belén", "departamento": "Boyacá"},
{"municipio": "Berbeo", "departamento": "Boyacá"},
{"municipio": "Betéitiva", "departamento": "Boyacá"},
{"municipio": "Boavita", "departamento": "Boyacá"},
{"municipio": "Boyacá", "departamento": "Boyacá"},
{"municipio": "Briceño", "departamento": "Boyacá"},
{"municipio": "Buenavista", "departamento": "Boyacá"},
{"municipio": "Busbanzá", "departamento": "Boyacá"},
{"municipio": "Caldas", "departamento": "Boyacá"},
{"municipio": "Campohermoso", "departamento": "Boyacá"},
{"municipio": "Cerinza", "departamento": "Boyacá"},
{"municipio": "Chinavita", "departamento": "Boyacá"},
{"municipio": "Chiquinquirá", "departamento": "Boyacá"},
{"municipio": "Chíquiza", "departamento": "Boyacá"},
{"municipio": "Chiscas", "departamento": "Boyacá"},
{"municipio": "Chita", "departamento": "Boyacá"},
{"municipio": "Chitaraque", "departamento": "Boyacá"},
{"municipio": "Chivatá", "departamento": "Boyacá"},
{"municipio": "Chivor", "departamento": "Boyacá"},
{"municipio": "Ciénega", "departamento": "Boyacá"},
{"municipio": "Cómbita", "departamento": "Boyacá"},
{"municipio": "Coper", "departamento": "Boyacá"},
{"municipio": "Corrales", "departamento": "Boyacá"},
{"municipio": "Covarachía", "departamento": "Boyacá"},
{"municipio": "Cubará", "departamento": "Boyacá"},
{"municipio": "Cucaita", "departamento": "Boyacá"},
{"municipio": "Cuítiva", "departamento": "Boyacá"},
{"municipio": "Duitama", "departamento": "Boyacá"},
{"municipio": "El Cocuy", "departamento": "Boyacá"},
{"municipio": "El Espino", "departamento": "Boyacá"},
{"municipio": "Firavitoba", "departamento": "Boyacá"},
{"municipio": "Floresta", "departamento": "Boyacá"},
{"municipio": "Gachantivá", "departamento": "Boyacá"},
{"municipio": "Gámeza", "departamento": "Boyacá"},
{"municipio": "Garagoa", "departamento": "Boyacá"},
{"municipio": "Guacamayas", "departamento": "Boyacá"},
{"municipio": "Guateque", "departamento": "Boyacá"},
{"municipio": "Guayatá", "departamento": "Boyacá"},
{"municipio": "Güicán", "departamento": "Boyacá"},
{"municipio": "Iza", "departamento": "Boyacá"},
{"municipio": "Jenesano", "departamento": "Boyacá"},
{"municipio": "Jericó", "departamento": "Boyacá"},
{"municipio": "La Capilla", "departamento": "Boyacá"},
{"municipio": "La Uvita", "departamento": "Boyacá"},
{"municipio": "La Victoria", "departamento": "Boyacá"},
{"municipio": "Labranzagrande", "departamento": "Boyacá"},
{"municipio": "Macanal", "departamento": "Boyacá"},
{"municipio": "Maripí", "departamento": "Boyacá"},
{"municipio": "Miraflores", "departamento": "Boyacá"},
{"municipio": "Mongua", "departamento": "Boyacá"},
{"municipio": "Monguí", "departamento": "Boyacá"},
{"municipio": "Moniquirá", "departamento": "Boyacá"},
{"municipio": "Motavita", "departamento": "Boyacá"},
{"municipio": "Muzo", "departamento": "Boyacá"},
{"municipio": "Nobsa", "departamento": "Boyacá"},
{"municipio": "Nuevo Colón", "departamento": "Boyacá"},
{"municipio": "Oicatá", "departamento": "Boyacá"},
{"municipio": "Otanche", "departamento": "Boyacá"},
{"municipio": "Pachavita", "departamento": "Boyacá"},
{"municipio": "Páez", "departamento": "Boyacá"},
{"municipio": "Paipa", "departamento": "Boyacá"},
{"municipio": "Pajarito", "departamento": "Boyacá"},
{"municipio": "Panqueba", "departamento": "Boyacá"},
{"municipio": "Pauna", "departamento": "Boyacá"},
{"municipio": "Paya", "departamento": "Boyacá"},
{"municipio": "Paz de Río", "departamento": "Boyacá"},
{"municipio": "Pesca", "departamento": "Boyacá"},
{"municipio": "Pisba", "departamento": "Boyacá"},
{"municipio": "Puerto Boyacá", "departamento": "Boyacá"},
{"municipio": "Quípama", "departamento": "Boyacá"},
{"municipio": "Ramiriquí", "departamento": "Boyacá"},
{"municipio": "Ráquira", "departamento": "Boyacá"},
{"municipio": "Rondón", "departamento": "Boyacá"},
{"municipio": "Saboyá", "departamento": "Boyacá"},
{"municipio": "Sáchica", "departamento": "Boyacá"},
{"municipio": "Samacá", "departamento": "Boyacá"},
{"municipio": "San Eduardo", "departamento": "Boyacá"},
{"municipio": "San José de Pare", "departamento": "Boyacá"},
{"municipio": "San Luis de Gaceno", "departamento": "Boyacá"},
{"municipio": "San Mateo", "departamento": "Boyacá"},
{"municipio": "San Miguel de Sema", "departamento": "Boyacá"},
{"municipio": "San Pablo de Borbur", "departamento": "Boyacá"},
{"municipio": "Santa María", "departamento": "Boyacá"},
{"municipio": "Santa Rosa de Viterbo", "departamento": "Boyacá"},
{"municipio": "Santa Sofía", "departamento": "Boyacá"},
{"municipio": "Santana", "departamento": "Boyacá"},
{"municipio": "Sativanorte", "departamento": "Boyacá"},
{"municipio": "Sativasur", "departamento": "Boyacá"},
{"municipio": "Siachoque", "departamento": "Boyacá"},
{"municipio": "Soatá", "departamento": "Boyacá"},
{"municipio": "Socha", "departamento": "Boyacá"},
{"municipio": "Socotá", "departamento": "Boyacá"},
{"municipio": "Sogamoso", "departamento": "Boyacá"},
{"municipio": "Somondoco", "departamento": "Boyacá"},
{"municipio": "Sora", "departamento": "Boyacá"},
{"municipio": "Soracá", "departamento": "Boyacá"},
{"municipio": "Sotaquirá", "departamento": "Boyacá"},
{"municipio": "Susacón", "departamento": "Boyacá"},
{"municipio": "Sutamarchán", "departamento": "Boyacá"},
{"municipio": "Sutatenza", "departamento": "Boyacá"},
{"municipio": "Tasco", "departamento": "Boyacá"},
{"municipio": "Tenza", "departamento": "Boyacá"},
{"municipio": "Tibaná", "departamento": "Boyacá"},
{"municipio": "Tibasosa", "departamento": "Boyacá"},
{"municipio": "Tinjacá", "departamento": "Boyacá"},
{"municipio": "Tipacoque", "departamento": "Boyacá"},
{"municipio": "Toca", "departamento": "Boyacá"},
{"municipio": "Togüí", "departamento": "Boyacá"},
{"municipio": "Tópaga", "departamento": "Boyacá"},
{"municipio": "Tota", "departamento": "Boyacá"},
{"municipio": "Tunja", "departamento": "Boyacá"},
{"municipio": "Tunungua", "departamento": "Boyacá"},
{"municipio": "Turmequé", "departamento": "Boyacá"},
{"municipio": "Tuta", "departamento": "Boyacá"},
{"municipio": "Tutazá", "departamento": "Boyacá"},
{"municipio": "Úmbita", "departamento": "Boyacá"},
{"municipio": "Ventaquemada", "departamento": "Boyacá"},
{"municipio": "Villa de Leyva", "departamento": "Boyacá"},
{"municipio": "Viracachá", "departamento": "Boyacá"},
{"municipio": "Zetaquira", "departamento": "Boyacá"},
{"municipio": "Aguadas", "departamento": "Caldas"},
{"municipio": "Anserma", "departamento": "Caldas"},
{"municipio": "Aranzazu", "departamento": "Caldas"},
{"municipio": "Belalcázar", "departamento": "Caldas"},
{"municipio": "Chinchiná", "departamento": "Caldas"},
{"municipio": "Filadelfia", "departamento": "Caldas"},
{"municipio": "La Dorada", "departamento": "Caldas"},
{"municipio": "La Merced", "departamento": "Caldas"},
{"municipio": "Manizales", "departamento": "Caldas"},
{"municipio": "Manzanares", "departamento": "Caldas"},
{"municipio": "Marmato", "departamento": "Caldas"},
{"municipio": "Marquetalia", "departamento": "Caldas"},
{"municipio": "Marulanda", "departamento": "Caldas"},
{"municipio": "Neira", "departamento": "Caldas"},
{"municipio": "Norcasia", "departamento": "Caldas"},
{"municipio": "Pácora", "departamento": "Caldas"},
{"municipio": "Palestina", "departamento": "Caldas"},
{"municipio": "Pensilvania", "departamento": "Caldas"},
{"municipio": "Riosucio", "departamento": "Caldas"},
{"municipio": "Risaralda", "departamento": "Caldas"},
{"municipio": "Salamina", "departamento": "Caldas"},
{"municipio": "Samaná", "departamento": "Caldas"},
{"municipio": "San José", "departamento": "Caldas"},
{"municipio": "Supía", "departamento": "Caldas"},
{"municipio": "Victoria", "departamento": "Caldas"},
{"municipio": "Villamaría", "departamento": "Caldas"},
{"municipio": "Viterbo", "departamento": "Caldas"},
{"municipio": "Albania", "departamento": "Caquetá"},
{"municipio": "Belén de los Andaquies", "departamento": "Caquetá"},
{"municipio": "Cartagena del Chairá", "departamento": "Caquetá"},
{"municipio": "Curillo", "departamento": "Caquetá"},
{"municipio": "El Doncello", "departamento": "Caquetá"},
{"municipio": "El Paujil", "departamento": "Caquetá"},
{"municipio": "Florencia", "departamento": "Caquetá"},
{"municipio": "La Montañita", "departamento": "Caquetá"},
{"municipio": "Morelia", "departamento": "Caquetá"},
{"municipio": "Puerto Milán", "departamento": "Caquetá"},
{"municipio": "Puerto Rico", "departamento": "Caquetá"},
{"municipio": "San José del Fragua", "departamento": "Caquetá"},
{"municipio": "San Vicente del Caguán", "departamento": "Caquetá"},
{"municipio": "Solano", "departamento": "Caquetá"},
{"municipio": "Solita", "departamento": "Caquetá"},
{"municipio": "Valparaíso", "departamento": "Caquetá"},
{"municipio": "Aguazul", "departamento": "Casanare"},
{"municipio": "Chámeza", "departamento": "Casanare"},
{"municipio": "Hato Corozal", "departamento": "Casanare"},
{"municipio": "La Salina", "departamento": "Casanare"},
{"municipio": "Maní", "departamento": "Casanare"},
{"municipio": "Monterrey", "departamento": "Casanare"},
{"municipio": "Nunchía", "departamento": "Casanare"},
{"municipio": "Orocué", "departamento": "Casanare"},
{"municipio": "Paz de Ariporo", "departamento": "Casanare"},
{"municipio": "Pore", "departamento": "Casanare"},
{"municipio": "Recetor", "departamento": "Casanare"},
{"municipio": "Sabanalarga", "departamento": "Casanare"},
{"municipio": "Sácama", "departamento": "Casanare"},
{"municipio": "San Luis de Palenque", "departamento": "Casanare"},
{"municipio": "Támara", "departamento": "Casanare"},
{"municipio": "Tauramena", "departamento": "Casanare"},
{"municipio": "Trinidad", "departamento": "Casanare"},
{"municipio": "Villanueva", "departamento": "Casanare"},
{"municipio": "Yopal", "departamento": "Casanare"},
{"municipio": "Almaguer", "departamento": "Cauca"},
{"municipio": "Argelia", "departamento": "Cauca"},
{"municipio": "Balboa", "departamento": "Cauca"},
{"municipio": "Bolívar", "departamento": "Cauca"},
{"municipio": "Buenos Aires", "departamento": "Cauca"},
{"municipio": "Cajibío", "departamento": "Cauca"},
{"municipio": "Caldono", "departamento": "Cauca"},
{"municipio": "Caloto", "departamento": "Cauca"},
{"municipio": "Corinto", "departamento": "Cauca"},
{"municipio": "El Tambo", "departamento": "Cauca"},
{"municipio": "Florencia", "departamento": "Cauca"},
{"municipio": "Guachené", "departamento": "Cauca"},
{"municipio": "Guapí", "departamento": "Cauca"},
{"municipio": "Inzá", "departamento": "Cauca"},
{"municipio": "Jambaló", "departamento": "Cauca"},
{"municipio": "La Sierra", "departamento": "Cauca"},
{"municipio": "La Vega", "departamento": "Cauca"},
{"municipio": "López de Micay", "departamento": "Cauca"},
{"municipio": "Mercaderes", "departamento": "Cauca"},
{"municipio": "Miranda", "departamento": "Cauca"},
{"municipio": "Morales", "departamento": "Cauca"},
{"municipio": "Padilla", "departamento": "Cauca"},
{"municipio": "Páez", "departamento": "Cauca"},
{"municipio": "Patía", "departamento": "Cauca"},
{"municipio": "Piamonte", "departamento": "Cauca"},
{"municipio": "Piendamó", "departamento": "Cauca"},
{"municipio": "Popayán", "departamento": "Cauca"},
{"municipio": "Puerto Tejada", "departamento": "Cauca"},
{"municipio": "Puracé", "departamento": "Cauca"},
{"municipio": "Rosas", "departamento": "Cauca"},
{"municipio": "San Sebastián", "departamento": "Cauca"},
{"municipio": "Santa Rosa", "departamento": "Cauca"},
{"municipio": "Santander de Quilichao", "departamento": "Cauca"},
{"municipio": "Silvia", "departamento": "Cauca"},
{"municipio": "Sotará", "departamento": "Cauca"},
{"municipio": "Suárez", "departamento": "Cauca"},
{"municipio": "Sucre", "departamento": "Cauca"},
{"municipio": "Timbío", "departamento": "Cauca"},
{"municipio": "Timbiquí", "departamento": "Cauca"},
{"municipio": "Toribío", "departamento": "Cauca"},
{"municipio": "Totoró", "departamento": "Cauca"},
{"municipio": "Villa Rica", "departamento": "Cauca"},
{"municipio": "Aguachica", "departamento": "Cesar"},
{"municipio": "Agustín Codazzi", "departamento": "Cesar"},
{"municipio": "Astrea", "departamento": "Cesar"},
{"municipio": "Becerril", "departamento": "Cesar"},
{"municipio": "Bosconia", "departamento": "Cesar"},
{"municipio": "Chimichagua", "departamento": "Cesar"},
{"municipio": "Chiriguaná", "departamento": "Cesar"},
{"municipio": "Curumaní", "departamento": "Cesar"},
{"municipio": "El Copey", "departamento": "Cesar"},
{"municipio": "El Paso", "departamento": "Cesar"},
{"municipio": "Gamarra", "departamento": "Cesar"},
{"municipio": "González", "departamento": "Cesar"},
{"municipio": "La Gloria", "departamento": "Cesar"},
{"municipio": "La Jagua de Ibirico", "departamento": "Cesar"},
{"municipio": "La Paz", "departamento": "Cesar"},
{"municipio": "Manaure Balcón del Cesar", "departamento": "Cesar"},
{"municipio": "Pailitas", "departamento": "Cesar"},
{"municipio": "Pelaya", "departamento": "Cesar"},
{"municipio": "Pueblo Bello", "departamento": "Cesar"},
{"municipio": "Río de Oro", "departamento": "Cesar"},
{"municipio": "San Alberto", "departamento": "Cesar"},
{"municipio": "San Diego", "departamento": "Cesar"},
{"municipio": "San Martín", "departamento": "Cesar"},
{"municipio": "Tamalameque", "departamento": "Cesar"},
{"municipio": "Valledupar", "departamento": "Cesar"},
{"municipio": "Acandí", "departamento": "Chocó"},
{"municipio": "Alto Baudó", "departamento": "Chocó"},
{"municipio": "Bagadó", "departamento": "Chocó"},
{"municipio": "Bahía Solano", "departamento": "Chocó"},
{"municipio": "Bajo Baudó", "departamento": "Chocó"},
{"municipio": "Belén de Bajirá", "departamento": "Chocó"},
{"municipio": "Bojayá", "departamento": "Chocó"},
{"municipio": "Cértegui", "departamento": "Chocó"},
{"municipio": "Condoto", "departamento": "Chocó"},
{"municipio": "Cantón de San Pablo", "departamento": "Chocó"},
{"municipio": "El Atrato", "departamento": "Chocó"},
{"municipio": "El Carmen de Atrato", "departamento": "Chocó"},
{"municipio": "El Carmen del Darién", "departamento": "Chocó"},
{"municipio": "El Litoral de San Juan", "departamento": "Chocó"},
{"municipio": "Istmina", "departamento": "Chocó"},
{"municipio": "Juradó", "departamento": "Chocó"},
{"municipio": "Lloró", "departamento": "Chocó"},
{"municipio": "Medio Atrato", "departamento": "Chocó"},
{"municipio": "Medio Baudó", "departamento": "Chocó"},
{"municipio": "Medio San Juan", "departamento": "Chocó"},
{"municipio": "Nóvita", "departamento": "Chocó"},
{"municipio": "Nuquí", "departamento": "Chocó"},
{"municipio": "Quibdó", "departamento": "Chocó"},
{"municipio": "Río Iró", "departamento": "Chocó"},
{"municipio": "Río Quito", "departamento": "Chocó"},
{"municipio": "Riosucio", "departamento": "Chocó"},
{"municipio": "San José del Palmar", "departamento": "Chocó"},
{"municipio": "Sipí", "departamento": "Chocó"},
{"municipio": "Tadó", "departamento": "Chocó"},
{"municipio": "Unguía", "departamento": "Chocó"},
{"municipio": "Unión Panamericana", "departamento": "Chocó"},
{"municipio": "Ayapel", "departamento": "Córdoba"},
{"municipio": "Buenavista", "departamento": "Córdoba"},
{"municipio": "Canalete", "departamento": "Córdoba"},
{"municipio": "Cereté", "departamento": "Córdoba"},
{"municipio": "Chimá", "departamento": "Córdoba"},
{"municipio": "Chinú", "departamento": "Córdoba"},
{"municipio": "Ciénaga de Oro", "departamento": "Córdoba"},
{"municipio": "Cotorra", "departamento": "Córdoba"},
{"municipio": "La Apartada", "departamento": "Córdoba"},
{"municipio": "Los Córdobas", "departamento": "Córdoba"},
{"municipio": "Momil", "departamento": "Córdoba"},
{"municipio": "Montelíbano", "departamento": "Córdoba"},
{"municipio": "Montería", "departamento": "Córdoba"},
{"municipio": "Moñitos", "departamento": "Córdoba"},
{"municipio": "Planeta Rica", "departamento": "Córdoba"},
{"municipio": "Pueblo Nuevo", "departamento": "Córdoba"},
{"municipio": "Puerto Escondido", "departamento": "Córdoba"},
{"municipio": "Puerto Libertador", "departamento": "Córdoba"},
{"municipio": "Purísima de la Concepción", "departamento": "Córdoba"},
{"municipio": "Sahagún", "departamento": "Córdoba"},
{"municipio": "San Andrés de Sotavento", "departamento": "Córdoba"},
{"municipio": "San Antero", "departamento": "Córdoba"},
{"municipio": "San Bernardo del Viento", "departamento": "Córdoba"},
{"municipio": "San Carlos", "departamento": "Córdoba"},
{"municipio": "San José de Uré", "departamento": "Córdoba"},
{"municipio": "San Pelayo", "departamento": "Córdoba"},
{"municipio": "Santa Cruz de Lorica", "departamento": "Córdoba"},
{"municipio": "Tierralta", "departamento": "Córdoba"},
{"municipio": "Tuchín", "departamento": "Córdoba"},
{"municipio": "Valencia", "departamento": "Córdoba"},
{"municipio": "Agua de Dios", "departamento": "Cundinamarca"},
{"municipio": "Albán", "departamento": "Cundinamarca"},
{"municipio": "Anapoima", "departamento": "Cundinamarca"},
{"municipio": "Anolaima", "departamento": "Cundinamarca"},
{"municipio": "Apulo", "departamento": "Cundinamarca"},
{"municipio": "Arbelaéz", "departamento": "Cundinamarca"},
{"municipio": "Beltrán", "departamento": "Cundinamarca"},
{"municipio": "Bituima", "departamento": "Cundinamarca"},
{"municipio": "Bojacá", "departamento": "Cundinamarca"},
{"municipio": "Cabrera", "departamento": "Cundinamarca"},
{"municipio": "Cachipay", "departamento": "Cundinamarca"},
{"municipio": "Cajicá", "departamento": "Cundinamarca"},
{"municipio": "Caparrapí", "departamento": "Cundinamarca"},
{"municipio": "Cáqueza", "departamento": "Cundinamarca"},
{"municipio": "Carmen de Carupa", "departamento": "Cundinamarca"},
{"municipio": "Chaguaní", "departamento": "Cundinamarca"},
{"municipio": "Chía", "departamento": "Cundinamarca"},
{"municipio": "Chipaque", "departamento": "Cundinamarca"},
{"municipio": "Choachí", "departamento": "Cundinamarca"},
{"municipio": "Chocontá", "departamento": "Cundinamarca"},
{"municipio": "Cogua", "departamento": "Cundinamarca"},
{"municipio": "Cota", "departamento": "Cundinamarca"},
{"municipio": "Cucunubá", "departamento": "Cundinamarca"},
{"municipio": "El Colegio", "departamento": "Cundinamarca"},
{"municipio": "El Peñón", "departamento": "Cundinamarca"},
{"municipio": "El Rosal", "departamento": "Cundinamarca"},
{"municipio": "Facatativá", "departamento": "Cundinamarca"},
{"municipio": "Fómeque", "departamento": "Cundinamarca"},
{"municipio": "Fosca", "departamento": "Cundinamarca"},
{"municipio": "Funza", "departamento": "Cundinamarca"},
{"municipio": "Fúquene", "departamento": "Cundinamarca"},
{"municipio": "Fusagasugá", "departamento": "Cundinamarca"},
{"municipio": "Gachalá", "departamento": "Cundinamarca"},
{"municipio": "Gachancipá", "departamento": "Cundinamarca"},
{"municipio": "Gachetá", "departamento": "Cundinamarca"},
{"municipio": "Gama", "departamento": "Cundinamarca"},
{"municipio": "Girardot", "departamento": "Cundinamarca"},
{"municipio": "Granada", "departamento": "Cundinamarca"},
{"municipio": "Guachetá", "departamento": "Cundinamarca"},
{"municipio": "Guaduas", "departamento": "Cundinamarca"},
{"municipio": "Guasca", "departamento": "Cundinamarca"},
{"municipio": "Guataquí", "departamento": "Cundinamarca"},
{"municipio": "Guatavita", "departamento": "Cundinamarca"},
{"municipio": "Guayabal de Síquima", "departamento": "Cundinamarca"},
{"municipio": "Guayabetal", "departamento": "Cundinamarca"},
{"municipio": "Gutiérrez", "departamento": "Cundinamarca"},
{"municipio": "Jerusalén", "departamento": "Cundinamarca"},
{"municipio": "Junín", "departamento": "Cundinamarca"},
{"municipio": "La Calera", "departamento": "Cundinamarca"},
{"municipio": "La Mesa", "departamento": "Cundinamarca"},
{"municipio": "La Palma", "departamento": "Cundinamarca"},
{"municipio": "La Peña", "departamento": "Cundinamarca"},
{"municipio": "La Vega", "departamento": "Cundinamarca"},
{"municipio": "Lenguazaque", "departamento": "Cundinamarca"},
{"municipio": "Machetá", "departamento": "Cundinamarca"},
{"municipio": "Madrid", "departamento": "Cundinamarca"},
{"municipio": "Manta", "departamento": "Cundinamarca"},
{"municipio": "Medina", "departamento": "Cundinamarca"},
{"municipio": "Mosquera", "departamento": "Cundinamarca"},
{"municipio": "Nariño", "departamento": "Cundinamarca"},
{"municipio": "Nemocón", "departamento": "Cundinamarca"},
{"municipio": "Nilo", "departamento": "Cundinamarca"},
{"municipio": "Nimaima", "departamento": "Cundinamarca"},
{"municipio": "Nocaima", "departamento": "Cundinamarca"},
{"municipio": "Pacho", "departamento": "Cundinamarca"},
{"municipio": "Paime", "departamento": "Cundinamarca"},
{"municipio": "Pandi", "departamento": "Cundinamarca"},
{"municipio": "Paratebueno", "departamento": "Cundinamarca"},
{"municipio": "Pasca", "departamento": "Cundinamarca"},
{"municipio": "Puerto Salgar", "departamento": "Cundinamarca"},
{"municipio": "Pulí", "departamento": "Cundinamarca"},
{"municipio": "Quebradanegra", "departamento": "Cundinamarca"},
{"municipio": "Quetame", "departamento": "Cundinamarca"},
{"municipio": "Quipile", "departamento": "Cundinamarca"},
{"municipio": "Ricaurte", "departamento": "Cundinamarca"},
{"municipio": "San Antonio del Tequendama", "departamento": "Cundinamarca"},
{"municipio": "San Bernardo", "departamento": "Cundinamarca"},
{"municipio": "San Cayetano", "departamento": "Cundinamarca"},
{"municipio": "San Francisco", "departamento": "Cundinamarca"},
{"municipio": "San Juan de Rioseco", "departamento": "Cundinamarca"},
{"municipio": "Sasaima", "departamento": "Cundinamarca"},
{"municipio": "Sesquilé", "departamento": "Cundinamarca"},
{"municipio": "Sibaté", "departamento": "Cundinamarca"},
{"municipio": "Silvania", "departamento": "Cundinamarca"},
{"municipio": "Simijaca", "departamento": "Cundinamarca"},
{"municipio": "Soacha", "departamento": "Cundinamarca"},
{"municipio": "Sopó", "departamento": "Cundinamarca"},
{"municipio": "Subachoque", "departamento": "Cundinamarca"},
{"municipio": "Suesca", "departamento": "Cundinamarca"},
{"municipio": "Supatá", "departamento": "Cundinamarca"},
{"municipio": "Susa", "departamento": "Cundinamarca"},
{"municipio": "Sutatausa", "departamento": "Cundinamarca"},
{"municipio": "Tabio", "departamento": "Cundinamarca"},
{"municipio": "Tausa", "departamento": "Cundinamarca"},
{"municipio": "Tena", "departamento": "Cundinamarca"},
{"municipio": "Tenjo", "departamento": "Cundinamarca"},
{"municipio": "Tibacuy", "departamento": "Cundinamarca"},
{"municipio": "Tibirita", "departamento": "Cundinamarca"},
{"municipio": "Tocaima", "departamento": "Cundinamarca"},
{"municipio": "Tocancipá", "departamento": "Cundinamarca"},
{"municipio": "Topaipí", "departamento": "Cundinamarca"},
{"municipio": "Ubalá", "departamento": "Cundinamarca"},
{"municipio": "Ubaque", "departamento": "Cundinamarca"},
{"municipio": "Ubaté", "departamento": "Cundinamarca"},
{"municipio": "Une", "departamento": "Cundinamarca"},
{"municipio": "Útica", "departamento": "Cundinamarca"},
{"municipio": "Venecia", "departamento": "Cundinamarca"},
{"municipio": "Vergara", "departamento": "Cundinamarca"},
{"municipio": "Vianí", "departamento": "Cundinamarca"},
{"municipio": "Villagómez", "departamento": "Cundinamarca"},
{"municipio": "Villapinzón", "departamento": "Cundinamarca"},
{"municipio": "Villeta", "departamento": "Cundinamarca"},
{"municipio": "Viotá", "departamento": "Cundinamarca"},
{"municipio": "Yacopí", "departamento": "Cundinamarca"},
{"municipio": "Zipacón", "departamento": "Cundinamarca"},
{"municipio": "Zipaquirá", "departamento": "Cundinamarca"},
{"municipio": "Bogotá", "departamento": "Distrito Capital"},
{"municipio": "Barrancominas", "departamento": "Guainía"},
{"municipio": "Cacahual", "departamento": "Guainía"},
{"municipio": "Inírida", "departamento": "Guainía"},
{"municipio": "La Guadalupe", "departamento": "Guainía"},
{"municipio": "Morichal Nuevo", "departamento": "Guainía"},
{"municipio": "Pana Pana", "departamento": "Guainía"},
{"municipio": "Puerto Colombia", "departamento": "Guainía"},
{"municipio": "San Felipe", "departamento": "Guainía"},
{"municipio": "Calamar", "departamento": "Guaviare"},
{"municipio": "El Retorno", "departamento": "Guaviare"},
{"municipio": "Miraflores", "departamento": "Guaviare"},
{"municipio": "San José del Guaviare", "departamento": "Guaviare"},
{"municipio": "Acevedo", "departamento": "Huila"},
{"municipio": "Agrado", "departamento": "Huila"},
{"municipio": "Aipe", "departamento": "Huila"},
{"municipio": "Algeciras", "departamento": "Huila"},
{"municipio": "Altamira", "departamento": "Huila"},
{"municipio": "Baraya", "departamento": "Huila"},
{"municipio": "Campoalegre", "departamento": "Huila"},
{"municipio": "Colombia", "departamento": "Huila"},
{"municipio": "Elías", "departamento": "Huila"},
{"municipio": "El Pital", "departamento": "Huila"},
{"municipio": "Garzón", "departamento": "Huila"},
{"municipio": "Gigante", "departamento": "Huila"},
{"municipio": "Guadalupe", "departamento": "Huila"},
{"municipio": "Hobo", "departamento": "Huila"},
{"municipio": "Íquira", "departamento": "Huila"},
{"municipio": "Isnos", "departamento": "Huila"},
{"municipio": "La Argentina", "departamento": "Huila"},
{"municipio": "La Plata", "departamento": "Huila"},
{"municipio": "Nátaga", "departamento": "Huila"},
{"municipio": "Neiva", "departamento": "Huila"},
{"municipio": "Oporapa", "departamento": "Huila"},
{"municipio": "Paicol", "departamento": "Huila"},
{"municipio": "Palermo", "departamento": "Huila"},
{"municipio": "Palestina", "departamento": "Huila"},
{"municipio": "Pitalito", "departamento": "Huila"},
{"municipio": "Rivera", "departamento": "Huila"},
{"municipio": "Saladoblanco", "departamento": "Huila"},
{"municipio": "San Agustín", "departamento": "Huila"},
{"municipio": "Santa María", "departamento": "Huila"},
{"municipio": "Suaza", "departamento": "Huila"},
{"municipio": "Tarqui", "departamento": "Huila"},
{"municipio": "Tello", "departamento": "Huila"},
{"municipio": "Teruel", "departamento": "Huila"},
{"municipio": "Tesalia", "departamento": "Huila"},
{"municipio": "Timaná", "departamento": "Huila"},
{"municipio": "Villavieja", "departamento": "Huila"},
{"municipio": "Yaguará", "departamento": "Huila"},
{"municipio": "Albania", "departamento": "La Guajira"},
{"municipio": "Barrancas", "departamento": "La Guajira"},
{"municipio": "Dibulla", "departamento": "La Guajira"},
{"municipio": "Distracción", "departamento": "La Guajira"},
{"municipio": "El Molino", "departamento": "La Guajira"},
{"municipio": "Fonseca", "departamento": "La Guajira"},
{"municipio": "Hatonuevo", "departamento": "La Guajira"},
{"municipio": "La Jagua del Pilar", "departamento": "La Guajira"},
{"municipio": "Maicao", "departamento": "La Guajira"},
{"municipio": "Manaure", "departamento": "La Guajira"},
{"municipio": "Riohacha", "departamento": "La Guajira"},
{"municipio": "San Juan del Cesar", "departamento": "La Guajira"},
{"municipio": "Uribia", "departamento": "La Guajira"},
{"municipio": "Urumita", "departamento": "La Guajira"},
{"municipio": "Villanueva", "departamento": "La Guajira"},
{"municipio": "Algarrobo", "departamento": "Magdalena"},
{"municipio": "Aracataca", "departamento": "Magdalena"},
{"municipio": "Ariguaní", "departamento": "Magdalena"},
{"municipio": "Cerro de San Antonio", "departamento": "Magdalena"},
{"municipio": "Chibolo", "departamento": "Magdalena"},
{"municipio": "Ciénaga", "departamento": "Magdalena"},
{"municipio": "Concordia", "departamento": "Magdalena"},
{"municipio": "El Banco", "departamento": "Magdalena"},
{"municipio": "El Piñón", "departamento": "Magdalena"},
{"municipio": "El Retén", "departamento": "Magdalena"},
{"municipio": "Fundación", "departamento": "Magdalena"},
{"municipio": "Guamal", "departamento": "Magdalena"},
{"municipio": "Nuava Granada", "departamento": "Magdalena"},
{"municipio": "Pedraza", "departamento": "Magdalena"},
{"municipio": "Pijiño del Carmen", "departamento": "Magdalena"},
{"municipio": "Pivijay", "departamento": "Magdalena"},
{"municipio": "Plato", "departamento": "Magdalena"},
{"municipio": "Pueblo Viejo", "departamento": "Magdalena"},
{"municipio": "Remolino", "departamento": "Magdalena"},
{"municipio": "Sabanas de San Ángel", "departamento": "Magdalena"},
{"municipio": "Salamina", "departamento": "Magdalena"},
{"municipio": "San Sebastián de Buenavista", "departamento": "Magdalena"},
{"municipio": "San Zenón", "departamento": "Magdalena"},
{"municipio": "Santa Ana", "departamento": "Magdalena"},
{"municipio": "Santa Bárbara de Pinto", "departamento": "Magdalena"},
{"municipio": "Santa Marta", "departamento": "Magdalena"},
{"municipio": "Sitionuevo", "departamento": "Magdalena"},
{"municipio": "Tenerife", "departamento": "Magdalena"},
{"municipio": "Zapayán", "departamento": "Magdalena"},
{"municipio": "Zona Bananera", "departamento": "Magdalena"},
{"municipio": "Acacías", "departamento": "Meta"},
{"municipio": "Barranca de Upía", "departamento": "Meta"},
{"municipio": "Cabuyaro", "departamento": "Meta"},
{"municipio": "Castilla La Nueva", "departamento": "Meta"},
{"municipio": "Cubarral", "departamento": "Meta"},
{"municipio": "Cumaral", "departamento": "Meta"},
{"municipio": "El Calvario", "departamento": "Meta"},
{"municipio": "El Castillo", "departamento": "Meta"},
{"municipio": "El Dorado", "departamento": "Meta"},
{"municipio": "Fuente de Oro", "departamento": "Meta"},
{"municipio": "Granada", "departamento": "Meta"},
{"municipio": "Guamal", "departamento": "Meta"},
{"municipio": "La Macarena", "departamento": "Meta"},
{"municipio": "La Uribe", "departamento": "Meta"},
{"municipio": "Lejanías", "departamento": "Meta"},
{"municipio": "Mapiripán", "departamento": "Meta"},
{"municipio": "Mesetas", "departamento": "Meta"},
{"municipio": "Puerto Concordia", "departamento": "Meta"},
{"municipio": "Puerto Gaitán", "departamento": "Meta"},
{"municipio": "Puerto Lleras", "departamento": "Meta"},
{"municipio": "Puerto López", "departamento": "Meta"},
{"municipio": "Puerto Rico", "departamento": "Meta"},
{"municipio": "Restrepo", "departamento": "Meta"},
{"municipio": "San Carlos de Guaroa", "departamento": "Meta"},
{"municipio": "San Juan de Arama", "departamento": "Meta"},
{"municipio": "San Juanito", "departamento": "Meta"},
{"municipio": "San Martín", "departamento": "Meta"},
{"municipio": "Villavicencio", "departamento": "Meta"},
{"municipio": "Vista Hermosa", "departamento": "Meta"},
{"municipio": "San José de Albán", "departamento": "Nariño"},
{"municipio": "Aldana", "departamento": "Nariño"},
{"municipio": "Ancuya", "departamento": "Nariño"},
{"municipio": "Arboleda", "departamento": "Nariño"},
{"municipio": "Barbacoas", "departamento": "Nariño"},
{"municipio": "Belén", "departamento": "Nariño"},
{"municipio": "Buesaco", "departamento": "Nariño"},
{"municipio": "Chachagüí", "departamento": "Nariño"},
{"municipio": "Colón", "departamento": "Nariño"},
{"municipio": "Consacá", "departamento": "Nariño"},
{"municipio": "Contadero", "departamento": "Nariño"},
{"municipio": "Córdoba", "departamento": "Nariño"},
{"municipio": "Cuaspud", "departamento": "Nariño"},
{"municipio": "Cumbal", "departamento": "Nariño"},
{"municipio": "Cumbitara", "departamento": "Nariño"},
{"municipio": "El Charco", "departamento": "Nariño"},
{"municipio": "El Peñol", "departamento": "Nariño"},
{"municipio": "El Rosario", "departamento": "Nariño"},
{"municipio": "El Tablón", "departamento": "Nariño"},
{"municipio": "El Tambo", "departamento": "Nariño"},
{"municipio": "Francisco Pizarro", "departamento": "Nariño"},
{"municipio": "Funes", "departamento": "Nariño"},
{"municipio": "Guachucal", "departamento": "Nariño"},
{"municipio": "Guaitarilla", "departamento": "Nariño"},
{"municipio": "Gualmatán", "departamento": "Nariño"},
{"municipio": "Iles", "departamento": "Nariño"},
{"municipio": "Imués", "departamento": "Nariño"},
{"municipio": "Ipiales", "departamento": "Nariño"},
{"municipio": "La Cruz", "departamento": "Nariño"},
{"municipio": "La Florida", "departamento": "Nariño"},
{"municipio": "La Llanada", "departamento": "Nariño"},
{"municipio": "La Tola", "departamento": "Nariño"},
{"municipio": "La Unión", "departamento": "Nariño"},
{"municipio": "Leiva", "departamento": "Nariño"},
{"municipio": "Linares", "departamento": "Nariño"},
{"municipio": "Los Andes", "departamento": "Nariño"},
{"municipio": "Magüí", "departamento": "Nariño"},
{"municipio": "Mallama", "departamento": "Nariño"},
{"municipio": "Mosquera", "departamento": "Nariño"},
{"municipio": "Nariño", "departamento": "Nariño"},
{"municipio": "Olaya Herrera", "departamento": "Nariño"},
{"municipio": "Ospina", "departamento": "Nariño"},
{"municipio": "Pasto", "departamento": "Nariño"},
{"municipio": "Policarpa", "departamento": "Nariño"},
{"municipio": "Potosí", "departamento": "Nariño"},
{"municipio": "Providencia", "departamento": "Nariño"},
{"municipio": "Puerres", "departamento": "Nariño"},
{"municipio": "Pupiales", "departamento": "Nariño"},
{"municipio": "Ricaurte", "departamento": "Nariño"},
{"municipio": "Roberto Payán", "departamento": "Nariño"},
{"municipio": "Samaniego", "departamento": "Nariño"},
{"municipio": "San Bernardo", "departamento": "Nariño"},
{"municipio": "San Lorenzo", "departamento": "Nariño"},
{"municipio": "San Pablo", "departamento": "Nariño"},
{"municipio": "San Pedro de Cartago", "departamento": "Nariño"},
{"municipio": "Sandoná", "departamento": "Nariño"},
{"municipio": "Santa Bárbara", "departamento": "Nariño"},
{"municipio": "Santacruz", "departamento": "Nariño"},
{"municipio": "Sapuyes", "departamento": "Nariño"},
{"municipio": "Taminango", "departamento": "Nariño"},
{"municipio": "Tangua", "departamento": "Nariño"},
{"municipio": "Tumaco", "departamento": "Nariño"},
{"municipio": "Túquerres", "departamento": "Nariño"},
{"municipio": "Yacuanquer", "departamento": "Nariño"},
{"municipio": "Ábrego", "departamento": "Norte de Santander"},
{"municipio": "Arboledas", "departamento": "Norte de Santander"},
{"municipio": "Bochalema", "departamento": "Norte de Santander"},
{"municipio": "Bucarasica", "departamento": "Norte de Santander"},
{"municipio": "Cáchira", "departamento": "Norte de Santander"},
{"municipio": "Cácota", "departamento": "Norte de Santander"},
{"municipio": "Chinácota", "departamento": "Norte de Santander"},
{"municipio": "Chitagá", "departamento": "Norte de Santander"},
{"municipio": "Convención", "departamento": "Norte de Santander"},
{"municipio": "Cúcuta", "departamento": "Norte de Santander"},
{"municipio": "Cucutilla", "departamento": "Norte de Santander"},
{"municipio": "Durania", "departamento": "Norte de Santander"},
{"municipio": "El Carmen", "departamento": "Norte de Santander"},
{"municipio": "El Tarra", "departamento": "Norte de Santander"},
{"municipio": "El Zulia", "departamento": "Norte de Santander"},
{"municipio": "Gramalote", "departamento": "Norte de Santander"},
{"municipio": "Hacarí", "departamento": "Norte de Santander"},
{"municipio": "Herrán", "departamento": "Norte de Santander"},
{"municipio": "La Esperanza", "departamento": "Norte de Santander"},
{"municipio": "La Playa de Belén", "departamento": "Norte de Santander"},
{"municipio": "Labateca", "departamento": "Norte de Santander"},
{"municipio": "Los Patios", "departamento": "Norte de Santander"},
{"municipio": "Lourdes", "departamento": "Norte de Santander"},
{"municipio": "Mutiscua", "departamento": "Norte de Santander"},
{"municipio": "Ocaña", "departamento": "Norte de Santander"},
{"municipio": "Pamplona", "departamento": "Norte de Santander"},
{"municipio": "Pamplonita", "departamento": "Norte de Santander"},
{"municipio": "Puerto Santander", "departamento": "Norte de Santander"},
{"municipio": "Ragonvalia", "departamento": "Norte de Santander"},
{"municipio": "Salazar de Las Palmas", "departamento": "Norte de Santander"},
{"municipio": "San Calixto", "departamento": "Norte de Santander"},
{"municipio": "San Cayetano", "departamento": "Norte de Santander"},
{"municipio": "Santiago", "departamento": "Norte de Santander"},
{"municipio": "Santo Domingo de Silos", "departamento": "Norte de Santander"},
{"municipio": "Sardinata", "departamento": "Norte de Santander"},
{"municipio": "Teorama", "departamento": "Norte de Santander"},
{"municipio": "Tibú", "departamento": "Norte de Santander"},
{"municipio": "Toledo", "departamento": "Norte de Santander"},
{"municipio": "Villa Caro", "departamento": "Norte de Santander"},
{"municipio": "Villa del Rosario", "departamento": "Norte de Santander"},
{"municipio": "Colón", "departamento": "Putumayo"},
{"municipio": "Mocoa", "departamento": "Putumayo"},
{"municipio": "Orito", "departamento": "Putumayo"},
{"municipio": "Puerto Asís", "departamento": "Putumayo"},
{"municipio": "Puerto Caicedo", "departamento": "Putumayo"},
{"municipio": "Puerto Guzmán", "departamento": "Putumayo"},
{"municipio": "Puerto Leguízamo", "departamento": "Putumayo"},
{"municipio": "San Francisco", "departamento": "Putumayo"},
{"municipio": "San Miguel", "departamento": "Putumayo"},
{"municipio": "Santiago", "departamento": "Putumayo"},
{"municipio": "Sibundoy", "departamento": "Putumayo"},
{"municipio": "Valle del Guamuez", "departamento": "Putumayo"},
{"municipio": "Villagarzón", "departamento": "Putumayo"},
{"municipio": "Armenia", "departamento": "Quindío"},
{"municipio": "Buenavista", "departamento": "Quindío"},
{"municipio": "Calarcá", "departamento": "Quindío"},
{"municipio": "Circasia", "departamento": "Quindío"},
{"municipio": "Córdoba", "departamento": "Quindío"},
{"municipio": "Filandia", "departamento": "Quindío"},
{"municipio": "Génova", "departamento": "Quindío"},
{"municipio": "La Tebaida", "departamento": "Quindío"},
{"municipio": "Montenegro", "departamento": "Quindío"},
{"municipio": "Pijao", "departamento": "Quindío"},
{"municipio": "Quimbaya", "departamento": "Quindío"},
{"municipio": "Salento", "departamento": "Quindío"},
{"municipio": "Apía", "departamento": "Risaralda"},
{"municipio": "Balboa", "departamento": "Risaralda"},
{"municipio": "Belén de Umbría", "departamento": "Risaralda"},
{"municipio": "Dosquebradas", "departamento": "Risaralda"},
{"municipio": "Guática", "departamento": "Risaralda"},
{"municipio": "La Celia", "departamento": "Risaralda"},
{"municipio": "La Virginia", "departamento": "Risaralda"},
{"municipio": "Marsella", "departamento": "Risaralda"},
{"municipio": "Mistrató", "departamento": "Risaralda"},
{"municipio": "Pereira", "departamento": "Risaralda"},
{"municipio": "Pueblo Rico", "departamento": "Risaralda"},
{"municipio": "Quinchía", "departamento": "Risaralda"},
{"municipio": "Santa Rosa de Cabal", "departamento": "Risaralda"},
{"municipio": "Santuario", "departamento": "Risaralda"},
{"municipio": "Isla de San Andrés", "departamento": "San Andrés y Providencia"},
{"municipio": "Providencia y Santa Catalina Islas", "departamento": "San Andrés y Providencia"},
{"municipio": "Aguada", "departamento": "Santander"},
{"municipio": "Albania", "departamento": "Santander"},
{"municipio": "Aratoca", "departamento": "Santander"},
{"municipio": "Barbosa", "departamento": "Santander"},
{"municipio": "Barichara", "departamento": "Santander"},
{"municipio": "Barrancabermeja", "departamento": "Santander"},
{"municipio": "Betulia", "departamento": "Santander"},
{"municipio": "Bolívar", "departamento": "Santander"},
{"municipio": "Bucaramanga", "departamento": "Santander"},
{"municipio": "Cabrera", "departamento": "Santander"},
{"municipio": "California", "departamento": "Santander"},
{"municipio": "Capitanejo", "departamento": "Santander"},
{"municipio": "Carcasí", "departamento": "Santander"},
{"municipio": "Cepitá", "departamento": "Santander"},
{"municipio": "Cerrito", "departamento": "Santander"},
{"municipio": "Charalá", "departamento": "Santander"},
{"municipio": "Charta", "departamento": "Santander"},
{"municipio": "Chima", "departamento": "Santander"},
{"municipio": "Chipatá", "departamento": "Santander"},
{"municipio": "Cimitarra", "departamento": "Santander"},
{"municipio": "Concepción", "departamento": "Santander"},
{"municipio": "Confines", "departamento": "Santander"},
{"municipio": "Contratación", "departamento": "Santander"},
{"municipio": "Coromoro", "departamento": "Santander"},
{"municipio": "Curití", "departamento": "Santander"},
{"municipio": "El Carmen de Chucurí", "departamento": "Santander"},
{"municipio": "El Guacamayo", "departamento": "Santander"},
{"municipio": "El Peñón", "departamento": "Santander"},
{"municipio": "El Playón", "departamento": "Santander"},
{"municipio": "Encino", "departamento": "Santander"},
{"municipio": "Enciso", "departamento": "Santander"},
{"municipio": "Florián", "departamento": "Santander"},
{"municipio": "Floridablanca", "departamento": "Santander"},
{"municipio": "Galán", "departamento": "Santander"},
{"municipio": "Gámbita", "departamento": "Santander"},
{"municipio": "Guaca", "departamento": "Santander"},
{"municipio": "Guadalupe", "departamento": "Santander"},
{"municipio": "Guapotá", "departamento": "Santander"},
{"municipio": "Guavatá", "departamento": "Santander"},
{"municipio": "Güepsa", "departamento": "Santander"},
{"municipio": "Hato", "departamento": "Santander"},
{"municipio": "Jesús María", "departamento": "Santander"},
{"municipio": "Jordán", "departamento": "Santander"},
{"municipio": "La Belleza", "departamento": "Santander"},
{"municipio": "La Paz", "departamento": "Santander"},
{"municipio": "Landázuri", "departamento": "Santander"},
{"municipio": "Lebrija", "departamento": "Santander"},
{"municipio": "Los Santos", "departamento": "Santander"},
{"municipio": "Macaravita", "departamento": "Santander"},
{"municipio": "Málaga", "departamento": "Santander"},
{"municipio": "Matanza", "departamento": "Santander"},
{"municipio": "Mogotes", "departamento": "Santander"},
{"municipio": "Molagavita", "departamento": "Santander"},
{"municipio": "Ocamonte", "departamento": "Santander"},
{"municipio": "Oiba", "departamento": "Santander"},
{"municipio": "Onzaga", "departamento": "Santander"},
{"municipio": "Palmar", "departamento": "Santander"},
{"municipio": "Palmas del Socorro", "departamento": "Santander"},
{"municipio": "Páramo", "departamento": "Santander"},
{"municipio": "Piedecuesta", "departamento": "Santander"},
{"municipio": "Pinchote", "departamento": "Santander"},
{"municipio": "Puente Nacional", "departamento": "Santander"},
{"municipio": "Puerto Parra", "departamento": "Santander"},
{"municipio": "Puerto Wilches", "departamento": "Santander"},
{"municipio": "Rionegro", "departamento": "Santander"},
{"municipio": "Sabana de Torres", "departamento": "Santander"},
{"municipio": "San Andrés", "departamento": "Santander"},
{"municipio": "San Benito", "departamento": "Santander"},
{"municipio": "San Gil", "departamento": "Santander"},
{"municipio": "San Joaquín", "departamento": "Santander"},
{"municipio": "San José de Miranda", "departamento": "Santander"},
{"municipio": "San Juan de Girón", "departamento": "Santander"},
{"municipio": "San Miguel", "departamento": "Santander"},
{"municipio": "San Vicente de Chucurí", "departamento": "Santander"},
{"municipio": "Santa Bárbara", "departamento": "Santander"},
{"municipio": "Santa Helena del Opón", "departamento": "Santander"},
{"municipio": "Simacota", "departamento": "Santander"},
{"municipio": "El Socorro", "departamento": "Santander"},
{"municipio": "Suaita", "departamento": "Santander"},
{"municipio": "Sucre", "departamento": "Santander"},
{"municipio": "Suratá", "departamento": "Santander"},
{"municipio": "Tona", "departamento": "Santander"},
{"municipio": "Valle de San José", "departamento": "Santander"},
{"municipio": "Vélez", "departamento": "Santander"},
{"municipio": "Vetas", "departamento": "Santander"},
{"municipio": "Villanueva", "departamento": "Santander"},
{"municipio": "Zapatoca", "departamento": "Santander"},
{"municipio": "Buenavista", "departamento": "Sucre"},
{"municipio": "Caimito", "departamento": "Sucre"},
{"municipio": "Chalán", "departamento": "Sucre"},
{"municipio": "Colosó", "departamento": "Sucre"},
{"municipio": "Corozal", "departamento": "Sucre"},
{"municipio": "Coveñas", "departamento": "Sucre"},
{"municipio": "El Roble", "departamento": "Sucre"},
{"municipio": "Galeras", "departamento": "Sucre"},
{"municipio": "Guaranda", "departamento": "Sucre"},
{"municipio": "La Unión", "departamento": "Sucre"},
{"municipio": "Los Palmitos", "departamento": "Sucre"},
{"municipio": "Majagual", "departamento": "Sucre"},
{"municipio": "Morroa", "departamento": "Sucre"},
{"municipio": "Ovejas", "departamento": "Sucre"},
{"municipio": "San Antonio de Palmito", "departamento": "Sucre"},
{"municipio": "Sampués", "departamento": "Sucre"},
{"municipio": "San Benito Abad", "departamento": "Sucre"},
{"municipio": "San Juan de Betulia", "departamento": "Sucre"},
{"municipio": "San Marcos", "departamento": "Sucre"},
{"municipio": "San Onofre", "departamento": "Sucre"},
{"municipio": "San Pedro", "departamento": "Sucre"},
{"municipio": "Santiago de Tolú", "departamento": "Sucre"},
{"municipio": "Sincé", "departamento": "Sucre"},
{"municipio": "Sincelejo", "departamento": "Sucre"},
{"municipio": "Sucre", "departamento": "Sucre"},
{"municipio": "Tolú Viejo", "departamento": "Sucre"},
{"municipio": "Alpujarra", "departamento": "Tolima"},
{"municipio": "Alvarado", "departamento": "Tolima"},
{"municipio": "Ambalema", "departamento": "Tolima"},
{"municipio": "Anzoátegui", "departamento": "Tolima"},
{"municipio": "Armero", "departamento": "Tolima"},
{"municipio": "Ataco", "departamento": "Tolima"},
{"municipio": "Cajamarca", "departamento": "Tolima"},
{"municipio": "Carmen de Apicalá", "departamento": "Tolima"},
{"municipio": "Casabianca", "departamento": "Tolima"},
{"municipio": "Chaparral", "departamento": "Tolima"},
{"municipio": "Coello", "departamento": "Tolima"},
{"municipio": "Coyaima", "departamento": "Tolima"},
{"municipio": "Cunday", "departamento": "Tolima"},
{"municipio": "Dolores", "departamento": "Tolima"},
{"municipio": "Espinal", "departamento": "Tolima"},
{"municipio": "Falan", "departamento": "Tolima"},
{"municipio": "Flandes", "departamento": "Tolima"},
{"municipio": "Fresno", "departamento": "Tolima"},
{"municipio": "Guamo", "departamento": "Tolima"},
{"municipio": "Herveo", "departamento": "Tolima"},
{"municipio": "Honda", "departamento": "Tolima"},
{"municipio": "Ibagué", "departamento": "Tolima"},
{"municipio": "Icononzo", "departamento": "Tolima"},
{"municipio": "Lérida", "departamento": "Tolima"},
{"municipio": "Líbano", "departamento": "Tolima"},
{"municipio": "Mariquita", "departamento": "Tolima"},
{"municipio": "Melgar", "departamento": "Tolima"},
{"municipio": "Murillo", "departamento": "Tolima"},
{"municipio": "Natagaima", "departamento": "Tolima"},
{"municipio": "Ortega", "departamento": "Tolima"},
{"municipio": "Palocabildo", "departamento": "Tolima"},
{"municipio": "Piedras", "departamento": "Tolima"},
{"municipio": "Planadas", "departamento": "Tolima"},
{"municipio": "Prado", "departamento": "Tolima"},
{"municipio": "Purificación", "departamento": "Tolima"},
{"municipio": "Rioblanco", "departamento": "Tolima"},
{"municipio": "Roncesvalles", "departamento": "Tolima"},
{"municipio": "Rovira", "departamento": "Tolima"},
{"municipio": "Saldaña", "departamento": "Tolima"},
{"municipio": "San Antonio", "departamento": "Tolima"},
{"municipio": "San Luis", "departamento": "Tolima"},
{"municipio": "Santa Isabel", "departamento": "Tolima"},
{"municipio": "Suárez", "departamento": "Tolima"},
{"municipio": "Valle de San Juan", "departamento": "Tolima"},
{"municipio": "Venadillo", "departamento": "Tolima"},
{"municipio": "Villahermosa", "departamento": "Tolima"},
{"municipio": "Villarrica", "departamento": "Tolima"},
{"municipio": "Alcalá", "departamento": "Valle del Cauca"},
{"municipio": "Andalucía", "departamento": "Valle del Cauca"},
{"municipio": "Ansermanuevo", "departamento": "Valle del Cauca"},
{"municipio": "Argelia", "departamento": "Valle del Cauca"},
{"municipio": "Bolívar", "departamento": "Valle del Cauca"},
{"municipio": "Buenaventura", "departamento": "Valle del Cauca"},
{"municipio": "Buga", "departamento": "Valle del Cauca"},
{"municipio": "Bugalagrande", "departamento": "Valle del Cauca"},
{"municipio": "Caicedonia", "departamento": "Valle del Cauca"},
{"municipio": "Cali", "departamento": "Valle del Cauca"},
{"municipio": "Candelaria", "departamento": "Valle del Cauca"},
{"municipio": "Cartago", "departamento": "Valle del Cauca"},
{"municipio": "Dagua", "departamento": "Valle del Cauca"},
{"municipio": "Darién", "departamento": "Valle del Cauca"},
{"municipio": "El Águila", "departamento": "Valle del Cauca"},
{"municipio": "El Cairo", "departamento": "Valle del Cauca"},
{"municipio": "El Cerrito", "departamento": "Valle del Cauca"},
{"municipio": "El Dovio", "departamento": "Valle del Cauca"},
{"municipio": "Florida", "departamento": "Valle del Cauca"},
{"municipio": "Ginebra", "departamento": "Valle del Cauca"},
{"municipio": "Guacarí", "departamento": "Valle del Cauca"},
{"municipio": "Jamundí", "departamento": "Valle del Cauca"},
{"municipio": "La Cumbre", "departamento": "Valle del Cauca"},
{"municipio": "La Unión", "departamento": "Valle del Cauca"},
{"municipio": "La Victoria", "departamento": "Valle del Cauca"},
{"municipio": "Obando", "departamento": "Valle del Cauca"},
{"municipio": "Palmira", "departamento": "Valle del Cauca"},
{"municipio": "Pradera", "departamento": "Valle del Cauca"},
{"municipio": "Restrepo", "departamento": "Valle del Cauca"},
{"municipio": "Riofrío", "departamento": "Valle del Cauca"},
{"municipio": "Roldanillo", "departamento": "Valle del Cauca"},
{"municipio": "San Pedro", "departamento": "Valle del Cauca"},
{"municipio": "Sevilla", "departamento": "Valle del Cauca"},
{"municipio": "Toro", "departamento": "Valle del Cauca"},
{"municipio": "Trujillo", "departamento": "Valle del Cauca"},
{"municipio": "Tuluá", "departamento": "Valle del Cauca"},
{"municipio": "Ulloa", "departamento": "Valle del Cauca"},
{"municipio": "Versalles", "departamento": "Valle del Cauca"},
{"municipio": "Vijes", "departamento": "Valle del Cauca"},
{"municipio": "Yotoco", "departamento": "Valle del Cauca"},
{"municipio": "Yumbo", "departamento": "Valle del Cauca"},
{"municipio": "Zarzal", "departamento": "Valle del Cauca"},
{"municipio": "Carurú", "departamento": "Vaupés"},
{"municipio": "Mitú", "departamento": "Vaupés"},
{"municipio": "Pacoa", "departamento": "Vaupés"},
{"municipio": "Papunaua", "departamento": "Vaupés"},
{"municipio": "Taraira", "departamento": "Vaupés"},
{"municipio": "Yavaraté", "departamento": "Vaupés"},
{"municipio": "Cumaribo", "departamento": "Vichada"},
{"municipio": "La Primavera", "departamento": "Vichada"},
{"municipio": "Puerto Carreño", "departamento": "Vichada"},
{"municipio": "Santa Rosalía", "departamento": "Vichada"}
]